export const MENU_ITEMS = [
  {
    id: 1,
    title: 'Home',
    path: '/',
  },
  {
    id: 2,
    title: 'Skills',
    path: '/skills',
  },
  {
    id: 3,
    title: 'Projects',
    path: '/projects/',
  },
  {
    id: 4,
    title: 'Educations',
    path: '/educations/',
  },
  {
    id: 5,
    title: 'Experiences',
    path: '/experiences/',
  },
];
